<template>
    <div class="page report-page">
      <Header/>
      <HotlineMobileCard/>
    <div class="container">
        <ChooseCustomer label="Chọn khách hàng để xem" buttonText="Chọn" v-on:click="changeCustomerHandle" noButton/>
        <div class="h5 font-weight--600 text--sm--center mb--sm--3 mb--md--10 mt--sm--10 mt--md--20">Luỹ kế theo chương trình</div>
            <div class="table-wrapper">
                <table class="report-table">
                    <thead>
                        <tr>
                            <td style="background-color: #7FD3FF; color: #001529; white-space: nowrap;">Chương trình</td>
                            <td style="background-color: #59C6BC; text-align: center">Mục tiêu</td>
                            <td style="background-color: #59C6BC; text-align: center">Đã thực hiện</td>
                            <td style="background-color: #59C6BC; text-align: center">Còn lại</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="campaign in campaigns" :key="campaign.campaignCode">
                            <td style="background-color: #CFE1E5;">{{campaign.campaignName}}</td>
                            <td style="background-color: #A5D7D3;">{{parseInt(campaign.target).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</td>
                            <td style="background-color: #B2DAD7;">{{parseInt(campaign.progress).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</td>
                            <td style="background-color: #BEDDDA;">{{(parseInt(campaign.target) - parseInt(campaign.progress)).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="h5 font-weight--600 text--sm--center mb--sm--3 mb--md--10 mt--sm--3 mt--md--10">Thống kê công nợ</div>
      <div class="table-wrapper">
        <table class="report-table">
            <thead>
                <tr>
                    <td style="background-color: #7FD3FF; color: #001529; white-space: nowrap;">Đơn hàng</td>
                    <td style="background-color: #59C6BC; text-align: center">Ngày order</td>
                    <td style="background-color: #59C6BC; text-align: center">Còn nợ</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="debtItem in debt" :key="debtItem.orderID">
                    <td style="background-color: #CFE1E5;">{{debtItem.orderID}}</td>
                    <td style="background-color: #A5D7D3;">{{debtItem.orderDate === '00000000' ? '00/00/0000' : $moment(debtItem.orderDate).format('DD/MM/YYYY')}}</td>
                    <td style="background-color: #B2DAD7;">{{parseInt(debtItem.amount).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td style="background-color: #7FD3FF; color: #001529">Tổng cộng</td>
                    <td style="background-color: #59C6BC;">{{debt.length}} đơn</td>
                    <td style="background-color: #59C6BC;">{{getDebtTotal.toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</td>
                </tr>
            </tfoot>
        </table>
      </div>
      </div>
      <Footer/>
      <FooterMobile/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import HotlineMobileCard from '@/components/HotlineMobileCard.vue'
import ChooseCustomer from '@/components/ChooseCustomer.vue'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    HotlineMobileCard,
    ChooseCustomer
  },
  data () {
    return {}
  },
  methods: {
    changeCustomerHandle (customer) {
      this.$store.dispatch('customer/getDebt', { businessPartnerID: this.$store.getters['auth/getToken'] })
      this.$store.dispatch('customer/getCampaigns', { customerID: this.$store.getters['auth/getToken'] })
    }
  },
  computed: {
    getDebtTotal () {
      return this.debt.reduce((total, debtItem) => {
        return total + parseInt(debtItem.amount)
      }, 0)
    },
    debt () {
      return this.$store.state.customer.debt
    },
    campaigns () {
      return this.$store.state.customer.campaigns
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: false,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', true)
    this.$store.dispatch('customer/getDebt', { businessPartnerID: this.$store.getters['auth/getToken'] })
    this.$store.dispatch('customer/getCampaigns', { customerID: this.$store.getters['auth/getToken'] })
  }
}
</script>
